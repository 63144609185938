import Banner from "../static/img/Home/Banner.jpg";
import CodingBanner from "../static/img/Home/coding-partner-banner.jpg";
import LanguageBanner from "../static/img/Home/language-partner-banner.jpeg";
import HiringBanner from "../static/img/Home/hiring-partner-banner.jpeg";
import Python from "../static/img/webDevelopment/python-icon.svg";
import Js from "../static/img/webDevelopment/js.svg";
import Angular from "../static/img/webDevelopment/angular-icon.svg";
import Node from "../static/img/webDevelopment/node-icon.svg";
import Django from "../static/img/webDevelopment/django-icon.svg";
import RectJs from "../static/img/webDevelopment/react-icon.svg";
export const lan = {
  en: {
    banner: [
      {
        heading: "Your Trusted Development Partner",
        bannerContent:
          ["We build enterprise-grade custom software solutions that enable businesses to unlock innovation and leverage digital transformation. We work from concept to code, and development to deployment making sure cost, time & quality of software development is optimized."," Koderbox is strongly focused on creating customer oriented Software applications. The Development team minutely examines and analyses the existing IT processes of the Clients. It does the gap analysis to ensure complete compatibility between existing system and custom developed the application. The applications are designed, tested and deployed to complete satisfaction of the Client."],
        bannerPath: Banner,
        // bannerPath: "/static/img/Home/Banner.jpg",
      },
      {
        heading: "Your Trusted Language Learning Partner",
        bannerContent:
          // "We build enterprise-grade custom software solutions that enable businesses to unlock innovation and leverage digital transformation. We work from concept to code, and development to deployment making sure cost, time & quality of software development is optimized.<br /><br /> Koderbox is strongly focused on creating customer oriented Software applications. The Development team minutely examines and analyses the existing IT processes of the Clients. It does the gap analysis to ensure complete compatibility between existing system and custom developed the application. The applications are designed, tested and deployed to complete satisfaction of the Client.",
        // bannerPath: "static/img/Home/Banner.jpg",
        ["We specialize in crafting tailored language learning solutions that empower students to master new languages and embrace cross-cultural communication. Our journey with students begins by conceptualizing effective strategies and translating them into tangible language skills. From conceptualization to implementation, we ensure the optimal balance of affordability, efficiency, and excellence in language education.","Koderbox's commitment to language education is unwavering. Our team delves into the intricacies of students' language learning needs, conducting meticulous analyses of their current linguistic capabilities. Through careful gap assessments, we bridge the divide between existing language skills and the proficiency they aim to achieve. Our programs are meticulously designed, rigorously tested, and thoughtfully executed to ensure students' utmost satisfaction and success in their language learning journey."],
        bannerPath: LanguageBanner,
      },
      {
        heading: "Your Trusted Coding Learning Partner",
        bannerContent:
          // "We build enterprise-grade custom software solutions that enable businesses to unlock innovation and leverage digital transformation. We work from concept to code, and development to deployment making sure cost, time & quality of software development is optimized.<br /><br /> Koderbox is strongly focused on creating customer oriented Software applications. The Development team minutely examines and analyses the existing IT processes of the Clients. It does the gap analysis to ensure complete compatibility between existing system and custom developed the application. The applications are designed, tested and deployed to complete satisfaction of the Client.",
           ["We specialize in being your trusted coding partner, dedicated to equipping students with comprehensive coding skills that lay the foundation for promising careers. Our approach begins with conceptualizing effective strategies and translating them into practical coding expertise. From conceptualization to execution, we ensure the perfect blend of affordability, efficiency, and excellence in coding education.","Koderbox's commitment to coding education is steadfast. Our team delves into the intricacies of students' coding aspirations, conducting thorough assessments of their current coding capabilities. Through meticulous gap analyses, we bridge the divide between existing coding skills and the proficiency they aspire to attain. Our programs are thoughtfully designed, rigorously tested, and meticulously executed to ensure students' utmost satisfaction and success in their coding journey, unlocking promising career opportunities along the way."],
          bannerPath: CodingBanner,
      },
      {
        heading: "Your Trusted Hiring Partner",
        bannerContent:
          // "We build enterprise-grade custom software solutions that enable businesses to unlock innovation and leverage digital transformation. We work from concept to code, and development to deployment making sure cost, time & quality of software development is optimized.<br /><br /> Koderbox is strongly focused on creating customer oriented Software applications. The Development team minutely examines and analyses the existing IT processes of the Clients. It does the gap analysis to ensure complete compatibility between existing system and custom developed the application. The applications are designed, tested and deployed to complete satisfaction of the Client.",
        ["As your reliable hiring partner, we specialize in connecting students with coveted positions at leading companies while also assisting companies in securing top-notch talent through our pool of trained engineers. Our approach is rooted in fostering a seamless transition between aspiring professionals and industry-leading organizations, ensuring mutually beneficial partnerships that drive success.","Koderbox's dedication to facilitating exceptional hiring experiences is unwavering. We meticulously evaluate students' skills, aspirations, and career goals, strategically aligning them with the right opportunities at esteemed companies. Simultaneously, we collaborate with companies to understand their specific requirements, presenting them with our pool of skilled and trained engineers who are equipped to excel in diverse roles. This symbiotic approach forms the foundation for successful collaborations, empowering students to embark on meaningful careers and enabling companies to access the talent they need to thrive."],
          bannerPath: HiringBanner,
      },
    ],

    about: [
      "About Us",
      "At Koderbox, we pride ourselves on being a versatile software development company with a mission to empower individuals through our Japanese and coding classes. Our unique approach ensures efficient and effective learning. However, our commitment extends beyond education; we also assist our students in finding job opportunities and connecting with the right companies.",
      "At Koderbox, we are more than just a software development company; we are your gateway to success in software, language learning, coding, and seamless job placements."






    ],
    info: [
      "Trusted Software Development Company",
      "As a trusted digital IT partner, we strongly believe in maintaining the highest integrity, " +
        "delivering consistent productivity along with top-notch quality, and be easily accessible at" +
        " all times.The applications are designed, tested and deployed to complete satisfaction of the clients." ,
        " KODERBOX TECHNOLOGIES is a full-service solution provider. Our head office is located in" +
        "Gurugram, Haryana and we support remote sites across multiple continents. We place our focus on " +
        "leveraging our infrastructure and footprint to support a broad spectrum of organizations with rapid " +
        "deployment and emerging technologies.",
      "KODERBOX TECHNOLOGIES stands as a full-service solution provider. Our head office, located in Gurugram, Haryana, empowers us to support remote sites across multiple continents. Our strategic focus revolves around leveraging our infrastructure and expansive reach to cater to a diverse range of organizations, enabling rapid deployment and the adoption of emerging technologies.",
      "In alignment with our commitment to education, our Language Learning and Coding Classes empower individuals with essential skills. Our Language Learning programs provide global connectivity, while our Coding Classes equip students with programming languages, frameworks, and tools vital for success in the competitive job market. Our expert instructors guide learners through a comprehensive curriculum tailored to various skill levels, creating a transformative learning journey."
    ],
    // mission:"Our mission is to build long term relationships with our customers, to provide exceptional services that exceed expectations, by pursuing business through innovation, advanced technology and adding value. ",
    mission:"Our Mission at Koderbox is to empower individuals and businesses through a holistic approach that encompasses software development, language learning, and coding classes. We strive to provide our clients with customer-oriented software applications while maintaining the highest standards of integrity, quality, and accessibility. Through our offerings, we aim to equip learners with the essential skills needed to excel in today's dynamic world. By fostering a culture of continuous learning and innovation, we contribute to the growth and success of individuals and organizations alike.",
    vision: "Our vision is to become the leader of choice for solutions, in the IT services. We want to empower and unleash the potential of all our customers. ",
    service_head: ["Our Featured Services"],
    service: [
      [
        "Web Development",
        "We develop static, dynamic and e-Commerce portals for our Clients. " +
          "The solutions we design and develop also enable integration with existing" +
          "applications as well as third-party tools like social media apps and multiple" +
          "payment gateways. The Websites we develop are responsive with user-friendly and amazing designs.",
      ],
      [
        "Mobile App Development",
        "Our Mobile App solutions combine strong mobile architecture built with the latest " +
          "technologies with good security and user experience design practices. From suggesting " +
          "a workable solution to implementing it; from subsequently deploying your applications " +
          "to the marketplace to maintaining and upgrading the applications, we provide full stack services.",
      ],
      [
        "Social Media Marketing",
        "Our experienced team of Search Engine Marketing strategists work tirelessly to " +
          "promote your website and to ensure that your brand achieves higher visibility " +
          "essentially through paid advertising. Search Engine Marketing (SEM) usually incorporates " +
          "Search Engine Optimization (SEO) by either adjusting or rewriting your website’s content..",
      ],
      [
        "Search Engine Optimization",
        "SEO or Search Engine Optimization helps in generating organic traffic through search " +
          "engines to optimize a website. Koderbox provide customized and innovative SEO solutions " +
          "in Gurgaon to help its clients in their business growth.Our Search Engine Optimization (SEO) " +
          "strategies and tactics can increase organic..",
      ],
      [
        "DevOps",
        "Under a DevOps model, development and operations teams are no longer “siloed.” Sometimes," +
          " these two teams are merged into a single team where the engineers work across the entire " +
          "application lifecycle, from development and test to deployment to operations, and develop a " +
          "range of skills not limited to a single function.",
      ],
      [
        "Language Classes",
        "We facilitate comprehensive language learning programs for our students. Our curated" +
        "curriculum spans from fundamental language skills to advanced fluency, catering to learners at " + 
        "all levels. Our approach is dynamic, encompassing listening, speaking, reading, and writing components.",
      ],
      [
        "Coding Classes",
        "We offer diverse coding classes to equip students for today's job market. Our curriculum spans programming languages,"+
        " frameworks, and tools, tailored for all levels. Expert instructors guide you through languages like Python, Java, JavaScript," +
        "fostering hands-on experience in application, web, and software development."
      ],
    ],
    approach: [
      "Our Value-Driven Approach To Software Development",
      "Outsourcing software development to koderbox means peace of mind. As one of the top IT outsourcing companies, we stay on schedule, scale the teams and ensure product quality. Even when working remotely with us, you will have the flexibility and full control over the project just like your in-house team. Here's the other reasons why we are among top software development companany.",
    ],
    app_list: [
      {
        head: "Discovery & business analysis",
        value:
          "Before starting, our team works with you to gain a deep understanding of your business operations and the case itself. Armed with this knowledge, we can turn your business needs into actionable project goals. All ideas generated undergo a thorough business analysis and feasibility studies before joining the project..",
      },
      {
        head: "Requirements & change management",
        value:
          "We seamlessly handle change requests, continuously absorbing stakeholder and user feedback to fuel our data-driven scope adjustment strategies. Through regular backlog refinement and prioritization, our team ensures that you get the product you expect and need — even with vague initial requirements.",
      },
      {
        head: "Budget management",
        value:
          "We rely on the Earned Value Management (EVM) technique to closely control the project budget by monitoring cost reports and performance rates daily, spotting variations, analyzing root causes, and taking corrective action to keep the project on track.",
      },
      {
        head: "Communication management",
        value:
          "We pay special attention to project communication in order to ensure complete transparency & enable productive collaboration. Besides voice- & video-conferencing & onsite meetings, our communication workflow includes structured knowledge management, thorough reporting on project status & regular health checks.",
      },
      {
        head: "Risk management & mitigation",
        value:
          "Take advantage of our low-risk, predictable Scrum process to accelerate your business beyond the competition. We have detailed procedures that allow us to identify risks early and propose optimal risk management strategies to ensure the success of the project.",
      },
      {
        head: "Schedule management",
        value:
          "During the early phases of the project, we construct a detailed delivery and communication plan with all key stakeholders. We also apply EVM to the project timeline, continually auditing the Definition of Ready (DoR) and the Definition of Done (DoD) to ensure the successful completion of project milestones.",
      },
      {
        head: "Build excellent quality solutions",
        value:
          "Take advantage of our custom software development services to improve the security of your solution, speed time to market, and deliver exceptional customer value.",
      },
    ],
    howWeDo: [
      {
        description:
          "Agile Software Development is an approach that is used to design a disciplined software management process which also allows some frequent alteration in the development project. This is a type of software development methodology that is one conceptual framework for undertaking various software engineering projects. It is used to minimize risk by developing software in short time boxes which are called iterations that generally last for one week to one month. <br/><br/> Develops is a popular term gaining a lot of attention because of the unconditional benefits it offers to its customers. The siloed process of Development and Operations is not the same as the inception of DevOps. These two departments are functioning together as a single team for all processes in the entire life-cycle. This works concurrently for all businesses. The continuous integration and continuous delivery model allow development and operational teams to perform everything simultaneously in development, quality assurance, security, and other operations.",
        method: {
          head: [
            "Based on their combined experience of developing software and helping others do that, the seventeen signatories to the manifesto proclaimed that they value:",
            "That is to say, the items on the left are valued more than the items on the right. It is not to say that the items on the right should be excluded in favor of the items on the left. Both sides have value, but from an Agile Development point of view the authors of the manifesto tip the balance in favor of the items on the left.",
          ],
          listItems: [
            ["Individuals and interactions over processes and tools.","Working software over comprehensive documentation.","Customer collaboration over contract negotiation.","Responding to change over following a plan."],
            ["Tools and processes are important, but it is more important to have competent people working together effectively.","Good documentation is useful in helping people to understand how the software is built and how to use it, but the main point of development is to create software, not documentation.","A contract is important but is no substitute for working closely with customers to discover what they need.","A project plan is important, but it must not be too rigid to accommodate changes in technology or the environment, stakeholders' priorities, and people's understanding of the problem and its solution."],
          ],
        },
        tech: [
          {
            name: "Python",
            image: Python,
          },
          {
            name: "JavaScript",
            image: Js,
          },
          {
            name: "Node",
            image: Node,
          },
          {
            name: "Django",
            image: Django,
          },
          {
            name: "ReactJs",
            image: RectJs,
          },
          {
            name: "Angular",
            image: Angular,
          },
        ],
      },
    ],
  },

  ja: {
    heading: ["あなたの信頼で" + "きる発達相棒"],
    about: [
      "私たちに関しては",
      "Koderboxは、顧客志向のソフトウェアアプリケーションの作成に重点を置いています",
    ],
    info: [
      "最も信頼できるソフトウェア開発会社",
      "信頼できるデジタルITパートナーとして、私たちは最高の整合性を維持し、最高の品質とともに一貫した生産性を提供し、いつでも簡単にアクセスできることを強く信じています。アプリケーションは、クライアントの完全な満足を実現するように設計、テスト、および展開されます。\n" +
        "KODERBOX TECHNOLOGIESは、フルサービスのソリューションプロバイダーです。 本社はハリヤーナ州グルガーオンにあり、複数の大陸にまたがるリモートサイトをサポートしています。 私たちは、インフラストラクチャとフットプリントを活用して、迅速な展開と新しいテクノロジーを備えた幅広い組織をサポートすることに重点を置いています。",
    ],
    service_head: ["私たちの注目のサービス"],
    service: [
      [
        "ソフトウェア開発をカスタマイズする",
        "私たちの専門知識は、顧客志向の（カスタマイズされた）ソフトウェアアプリケーションを作成することです。私たちの焦点とアプローチ" +
          "は、クライアントの事業運営に違いをもたらし、投資収益率（ROI）を最大化するスケーラブルなソリューションを提供することです。開発チ" +
          "ームは、クライアントの要件を詳細に調査および分析してから、アプリケーションを開発します。",
      ],
      [
        "モバイルアプリ開発",
        "当社のモバイルアプリソリューションは、最新のテクノロジーで構築された強力なモバイルアーキテクチャと、優れたセキュリティおよびユーザーエクスペリエンスの設計手法を組み合わせたものです。 実行可能なソリューションの提案から実装まで。 その後のアプリケーションの市場への展開から、アプリケーションの保守とアップグレードまで、フルスタックサービスを提供します。",
      ],
      [
        "ソーシャルメディアマーケティング",
        "検索エンジンマーケティングストラテジストの経験豊富なチームは、あなたのウェブサイトを宣伝し、あなたのブランドが本質的に有料広告を通じてより高い可視性を達成することを確実にするためにたゆまぬ努力をします。 検索エンジンマーケティング（SEM）は通常、ウェブサイトのコンテンツを調整または書き直し、サイトアーキテクチャを変更してクリック課金（PPC）リストを強化することにより、検索エンジン最適化（SEO）を組み込んでいます。",
      ],
      [
        "検索エンジン最適化",
        "SEOまたは検索エンジン最適化は、ウェブサイトを最適化するために検索エンジンを介して有機的なトラフィックを生成するのに役立ちます。 Koderboxは、グルガオンでカスタマイズされた革新的なSEOソリューションを提供し、クライアントのビジネスの成長を支援します。 結局のところ、より多くのトラフィックはより多くのコンバージョンにつながります。",
      ],
      [
        "ソフトウェア開発をカスタマイズする",
        "DevOpsモデルでは、開発チームと運用チームはもはや「サイロ化」されていません。 場合によっては、これら2つのチームが1つのチームに統合され、エンジニアは開発とテストから展開、運用に至るまでのアプリケーションライフサイクル全体にわたって作業し、単一の機能に限定されないさまざまなスキルを開発します。",
      ],
    ],
    approach: [
      "ソフトウェア開発への価値主導のアプローチ",
      "ソフトウェア開発をkoderboxにアウトソーシングすることは、安心を意味します。トップITアウトソーシング企業の1つとして、私たちはスケ" +
        "ジュールを守り、チームを拡大し、製品の品質を確保します。私たちとリモートで作業している場合でも、社内チームと同じように、プロジェクト" +
        "を柔軟に完全に制御できます。これが、私たちがソフトウェア開発のトップ企業の1つである他の理由です。",
    ],
    app_list: [
      {
        head: "発見と事業分析",
        value:
          "始める前に、私たちのチームはあなたと協力して、あなたの事業運営とケース自体を深く理解します。この知識を武器に、私たちはあなたのビジネスニーズを実行可能なプロジェクトの目標に変えることができます。生成されたすべてのアイデアは、プロジェクトに参加する前に、徹底的なビジネス分析と実現可能性調査を受けます。",
      },
      {
        head: "要件と変更管理",
        value:
          "変更リクエストをシームレスに処理し、関係者とユーザーのフィードバックを継続的に吸収して、データ主導のスコープ調整戦略を推進します。 定期的なバックログの改善と優先順位付けを通じて、私たちのチームは、漠然とした初期要件があっても、期待して必要な製品を確実に入手できるようにします。",
      },
      {
        head: "リスク管理と軽減",
        value:
          "低リスクで予測可能なスクラムプロセスを活用して、競合他社を超えてビジネスを加速させます。 リスクを早期に特定し、プロジェクトの成功を確実にするための最適なリスク管理戦略を提案するための詳細な手順があります。",
      },
      {
        head: "スケジュール管理",
        value:
          "プロジェクトの初期段階では、すべての主要な利害関係者との詳細な配信およびコミュニケーション計画を作成します。 また、EVMをプロジェクトのタイムラインに適用し、Ready of Ready（DoR）とDefinition of Done（DoD）を継続的に監査して、プロジェクトのマイルストーンが正常に完了したことを確認します。",
      },
      {
        head: "予算管理",
        value:
          "アーンドバリューマネジメント（EVM）手法を利用して、コストレポートとパフォーマンス率を毎日監視し、変動を特定し、根本原因を分析し、プロジェクトを軌道に乗せるための是正措置を講じることで、プロジェクトの予算を厳密に管理しています。",
      },
      {
        head: "コミュニケーションマネジメント",
        value:
          "完全な透明性を確保し、生産的なコラボレーションを可能にするために、プロジェクトのコミュニケーションに特別な注意を払っています。 音声およびビデオ会議とオンサイト会議に加えて、当社のコミュニケーションワークフローには、構造化された知識管理、プロジェクトステータスの徹底的なレポート、定期的なヘルスチェックが含まれます。",
      },
      {
        head: "優れた品質のソリューションを構築する",
        value:
          "当社のカスタムソフトウェア開発サービスを利用して、ソリューションのセキュリティを向上させ、市場投入までの時間を短縮し、卓越した顧客価値を提供します。",
      },
    ],
   
  },
};
